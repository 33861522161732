/**
* Generated automatically at built-time (2024-12-18T13:07:48.420Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "herd-black-friday",templateKey: "sites/92-1db34ba4-ae67-47ee-bc78-352ec6da43b7"};